<template>
  <div class="mt-3 col-md-12">
    <div class="row">
      <div class="col-md-6 mr-auto">
        <button
          type="button"
          class="btn btn_secondary"
          @click.prevent="$emit('closeModal')"
        >
          Cancelar
        </button>
      </div>
      <div class="col-md-6 ml-auto">
        <button
          type="button"
          :disabled="disabled"
          @click.prevent="$emit('sendGoal')"
          class="btn btn_default"
        >
          <i class="fas fa-plus"></i>
          Solicitar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "tabs", "disabled"],
  data() {
    return {
      indexSelect: 0,
      load: false,
      firstTab: true,
      secondTab: false,
      threeTab: false
    };
  },
  methods: {
    showTabCreate(index) {
      this.indexSelect = index;
      switch (index) {
        case 0:
          this.firstTab = true;
          this.secondTab = false;
          this.threeTab = false;
          break;
        case 1:
          this.firstTab = false;
          this.secondTab = true;
          this.threeTab = false;
          break;
        case 2:
          this.firstTab = false;
          this.secondTab = false;
          this.threeTab = true;
          break;
      }
    },
    getTab(tabName) {
      document.getElementById(`${tabName}-tab`).click();
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
