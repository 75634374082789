var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"modalId":"cpSolicitaMeta","modalTitle":'Solicitação Alteração de Meta',"load":_vm.load,"typeLg":"modal_md"},on:{"closeModal":_vm.closeModal}},[_c('ValidationObserver',{ref:"formNewGoal",staticClass:"col-md-12 px-0",attrs:{"tag":"form"}},[_c('div',{staticClass:"row m-auto"},[_c('div',{staticClass:"col-md-6 mb-1 form-group form-outline"},[_c('label',{staticClass:"mb-1 label_default"},[_vm._v("Meta atual")]),_c('input',{staticClass:"form-control input_default",attrs:{"type":"text","disabled":""},domProps:{"value":_vm.valueConvertBR(_vm.goalCurrent)}})]),_c('ValidationProvider',{staticClass:"col-md-6 mb-1 form-group form-outline",attrs:{"tag":"div","rules":{
          required: true,
          max: 12,
          validaValue: true,
          validaValueZero: true
        },"name":"Nova Meta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var ariaMsg = ref.ariaMsg;
        var classes = ref.classes;
return [_c('label',{staticClass:"mb-1 label_default"},[_vm._v("Nova Meta")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newGoal),expression:"newGoal"}],staticClass:"form-control input_default",class:classes,attrs:{"type":"text"},domProps:{"value":(_vm.newGoal)},on:{"keypress":function($event){return _vm.checkValueOnly($event)},"blur":function($event){_vm.newGoal = _vm.valueConvertBR(parseFloat($event.target.value))},"input":function($event){if($event.target.composing){ return; }_vm.newGoal=$event.target.value}}}),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('label',{staticClass:"label_info row col-md-10 m-auto"},[_vm._v(" O Valor solicitado só será alterado à partir do próximo mês ")]),_c('actions',{ref:"actions",attrs:{"disabled":_vm.disabled},on:{"update:disabled":function($event){_vm.disabled=$event},"closeModal":_vm.closeModal,"sendGoal":_vm.sendGoal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }